
import {Component, Vue} from 'vue-property-decorator'
import DosyaRaporTipiInfo from '@/components/infos/raporlama-ofispro/DosyaRaporTipiInfo.vue'

@Component({
  components: {DosyaRaporTipiInfo}
})
export default class KasaView extends Vue {
  avatarIcon: 'https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairDreads01&accessoriesType=Prescription02&hairColor=Brown&facialHairType=BeardLight&facialHairColor=Blonde&clotheType=GraphicShirt&clotheColor=Heather&graphicType=SkullOutline&eyeType=Happy&eyebrowType=RaisedExcited&mouthType=Twinkle&skinColor=Yellow' | undefined

  dateIcon = this.$helper.tarihStr(new Date())

}
