
import {Component, Vue, Mixins} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import DashboardInfo from "@/components/infos/raporlama-ofispro/DashboardInfo.vue";
import MuvekkilTableInfo from "@/components/infos/raporlama-ofispro/MuvekkilTableInfo.vue";
import AylıkGrafik from "@/components/infos/raporlama-ofispro/helper-comps/GenelIstatistikGrafikInfo.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {FiltrelemeEntity} from "@/entity/Raporlama/FiltrelemeEntity";

@Component({
  components: {AylıkGrafik, DashboardInfo, Dates, MuvekkilTableInfo}
})
export default class DosyaRaporTipiInfo extends Mixins(SimpleInputMixin) {


  localValue: FiltrelemeEntity = {
    baslangic_tarihi: new Date(),
    bitis_tarihi: new Date(),
    muvekkiller: []
  };

  muvekkilModel:any = []
  muvekkilItems = [
    { text: 'Çetin Sanğu' },
    { text: 'Nihan Erdoğan' },
    { text: 'Nilay Aydoğmuş' },
  ]

  gosterimSekliItems = ['Tablo Halinde', 'Grafik Halinde']
}

  //daha sonra kullanılacak
  // get toggleSelectAllMuvekkils() {
  //   if (this.muvekkilModel.length < this.muvekkilItems.length - 1) {
  //     return this.muvekkilModel = this.muvekkilItems.filter(item => item.text !== 'TÜMÜ');
  //   } else {
  //     return this.muvekkilModel = [];
  //   }
  // }

//   @Watch('dates', {deep:true})
//   onDateChange(){
//     this.fetchDataAndUpdateChart();
//   }
//
//   mounted() {
//     this.updateChart();
//   }
//
//   updateChart() {
//     const data = this.fetchDataAndUpdateChart(this.dates[0], this.dates[1]);
//     this.chartSeries[0].data = data;
//   }
//
//   async fetchDataAndUpdateChart() {
//     // buradan data fetch edeceğiz
//     // şimdilik dummydata kullandım
//     const data = [
//       { x: '2023-05-01', y: 30 },
//       { x: '2023-05-02', y: 40 },
//       { x: '2023-05-03', y: 35 },
//       { x: '2023-05-04', y: 50 },
//       { x: '2023-05-05', y: 49 },
//     ];
//
//     // seçilen tarihe göre filtrele
//     if(Array.isArray(data)) {
//       const filteredData = data.filter((item) => {
//         const date = new Date(item.x);
//         const startDate = new Date(this.dates[0]);
//         const endDate = new Date(this.dates[1]);
//
//         // tarih seçilen aralıkta mı kontrol
//         return date >= startDate && date <= endDate;
//       });
//       // chartSeries'i filtreli tarihe göre yeniden düzenle
//       this.chartSeries = [
//         {
//           name: 'Av.Nihan Erdoğan',
//           data: filteredData,
//         },
//       ];
//     }
//   }
// }
