
import {Vue, Component} from 'vue-property-decorator'
import widget from "@/components/infos/raporlama-ofispro/helper-comps/Widget.vue";

@Component({
  components:{widget}
})
export default class Dashboard extends Vue{

  created(){
    console.log(this.$route.path)
  }
}
